<template>
  <div
    class="v-arora-input v-arora-select"
    :class="{ disabled: disabled }"
    :inert="disabled"
  >
    <select
      class="v-form-control"
      :aria-label="label.toString()"
      :data-test-id="dataTestId"
      v-model="picked"
    >
      <option
        v-if="defaultOption"
        selected
        :aria-selected="defaultOption"
        :disabled="defaultOptionDisabled"
        :value="defaultOptionValue"
        v-html="defaultOptionText"
      />
      <template
        v-for="(_item, index) in options"
        :key="`${index.toString()}`"
      >
        <slot
          name="index"
          v-bind="{ index: index as number }"
        />
      </template>
    </select>
    <span class="v-arora-input__label v-arora-select__label">
      <slot name="label">
        <span
          class="v-pointer-events-none"
          v-html="label"
        />
      </slot>
      <span
        v-if="required"
        class="v-required"
      />
    </span>
  </div>
</template>

<script setup lang="ts" generic="T">
import type { VElement } from '@arora/common'

const { defaultOptionDisabled = true, selected = null } = defineProps<
  VElement & {
    selected?: T | null
    options?: T[]
    disabled?: boolean
    defaultOption?: boolean
    defaultOptionText?: string
    defaultOptionDisabled?: boolean
    defaultOptionValue?: T
  }
>()
const emit = defineEmits(['update:selected'])

const picked = computed<T | null>({
  get() {
    return selected
  },
  set(value) {
    emit('update:selected', value)
  }
})
</script>
